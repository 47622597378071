import Landing from "./pages/Landing"

import { Routes, Route } from "react-router-dom"
import Main from "./pages/Main"

function App(){
    return (
            <div>
                {/* <BrowserRouter> */}
                <Routes>
                    <Route path="/" element={<Landing />}/>
                    <Route path="/main" element={<Main />}/>
                    <Route path="/main/" element={<Main />}/>
                </Routes>
                {/* </BrowserRouter> */}
                
            </div>
    );
}

export default App;