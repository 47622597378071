import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';

// import Landing from './pages/Landing';
// import Main from './pages/Main';
import App from './App'
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router
} from "react-router-dom";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
{/* //  <App */}
<Router>
    <App />
  </Router>

</React.StrictMode>

// <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<Landing />}>
//         <Route index element={<Landing />} />
//         <Route path="landing" element={<Landing />} />
//           {/* <Route path=":teamId" element={<Team />} /> */}
//           {/* <Route path="new" element={<NewTeamForm />} /> */}
//           {/* <Route index element={<LeagueStandings />} /> */}
//         </Route>
//     </Routes>
//   </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
