import axios from 'axios'
import TypeIt from "typeit-react";
import './Main.css';

import {useEffect, useState} from 'react';

function Main() {
    
    const CLIENT_ID = "99c144629a47487cae1891a4583dc685"
    const REDIRECT_URI = "https://containseggs.xyz/main"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
    const RESPONSE_TYPE = "token"
    const SCOPES = 'user-read-recently-played user-top-read user-follow-read'

    const [token, setToken] = useState("")

    const [artists, setArtists] = useState([])

    // const [randomArtistNum1, setArtNum1] = useState(Math.floor(Math.random() * 4) + 1)
    // const [randomArtistNum2, setArtNum2] = useState(Math.floor(Math.random() * 4) + 1)
    // const [randomTrackNum, setTracKNum]= useState(Math.floor(Math.random() * 4) + 1)

    // data extraction
    const [username, setUsername] = useState("")
    // console.log(randomArtistNum1)

   

    useEffect(() => {
        const hash = window.location.hash
        let token = window.localStorage.getItem("token")

        if (!token && hash) {
            token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

            window.location.hash = ""
            window.localStorage.setItem("token", token)
        }

        setToken(token)

    }, [])


    const getData = async (e) => {
        // get username
        // e.preventDefault() -> best for if we are submitting somethign

        
        // user information and number of followers
        const {data} = await axios.get("https://api.spotify.com/v1/me", {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        setUsername(data.display_name)
        // console.log(data.display_name)
        
        
        // top artists
        const {artists} = await axios.get("https://api.spotify.com/v1/me/top/artists", {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                time_range: 'short_term',
                limit: 10
            }
        })
        setArtists(artists)
        // setUsername(data.display_name)
        console.log(artists)

        // // top songs
        // const {tracks} = await axios.get("https://api.spotify.com/v1/me/top/tracks", {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     },
        //     params: {

        //     }
        // })

        // // all available genres
        // const {genres} = await axios.get("https://api.spotify.com/v1/recommendations/available-genre-seeds", {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     },
        //     params: {

        //     }
        // })
      }
    

    

    const logout = () => {
        setToken("")
        window.localStorage.removeItem("token")
    }

    

    // const [buttonText, setButtonText] = useState("Freeze");
   
    const typeit = (instance) => {
        instance
          .pause(500)
          .type('Im a super advanced, incredibly smart, and devilishly handsome artificial intellegence designed by @shantanujhaveri and @beauhogan specifically to judge the music you listen to at USC and understand USC\'s listening culture.  <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('Im not as old as Jarvis - I am still learning. But I do have a pretty good understanding of the trash you guys play at USC <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('Im driven on data - your spotify data. So first things first, lets get access to all of your listening history <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .pause(250)
          .type('Sorry that there is no apple music right now, the dumb ass developers forget to configure me to be inclusive... <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('USC kids... Am I right. <br/>')
          .exec(async () => await console.log('done'))
          .exec(async () => await renderButtons());
        // instance.
        
        return instance;
      };
      
      const converse1 = (instance) => {
        instance
          .pause(500)
          .type('I am just are going to view what you have been listening to and silently judge. All of your data will be encrypted and nothing will be saved, so you dont have to worry about us stealing your data <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('Are you okay with me looking at your spotify now? <br/>')
          .exec(async () => await renderButtonsOne());
        // instance.
        
        return instance;
      };

    function renderButtons() {
        if (!token){
            document.getElementById('b1-1').style.display = 'block'
            document.getElementById('b1-2').style.display = 'block'
        }     
    }

    function renderButtonsOne() {
        if (!token){
            document.getElementById('b1-3').style.display = 'block'
            // document.getElementById('b1-2').style.display = 'block'
        }     
    }

    const changeButton = () => {
        document.getElementById('b1-1').style.display = 'none'
        document.getElementById('b1-2').style.background = 'lightgrey'  
        document.getElementById('b1-2').style.marginBottom = '10%'
        document.getElementById('c1').style.display = 'block'
    }

    var answerstring = ''

    const changeButtonOne = () => {
        document.getElementById('b1-5').style.display = 'none'
        document.getElementById('b1-4').style.background = 'lightgrey'  
        document.getElementById('b1-4').style.marginBottom = '10%'
        document.getElementById('c3').style.display = 'block'
        answerstring = 'Good, lets get going <br/>'

    }

    const changeButtonTwo  = () => {
        document.getElementById('b1-4').style.display = 'none'
        document.getElementById('b1-5').style.background = 'lightgrey'  
        document.getElementById('b1-5').style.marginBottom = '10%'
        document.getElementById('c3').style.display = 'block'
        answerstring = 'Too bad, I already got started <br/>'
    }

    // main talking stuff
    
    function renderButtonsTwo() {
       
            document.getElementById('b1-4').style.display = 'block'
            document.getElementById('b1-5').style.display = 'block'
      
    }
   
    const converse2 = (instance) => {
        instance
          .exec(async () => await getData())
          .pause(500)
          .type('Loading in your data and checking if you fit.<br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('You ready?<br/>')
          .exec(async () => await renderButtonsTwo());
        return instance;
      };

      console.log(username)
      console.log(typeof username)

      console.log(answerstring)
    var stringname = 'Okay lets see here ' + username + ' <br/>'
      const converse3 = (instance) => {
        instance
          .exec(async () => await getData())
          .type(answerstring)
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type(stringname)
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('... <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('... <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('lol <br/>')
          .pause(500)
          .type('<br/>')
          .pause(1000)
          .type('nice <br/>')
          .pause(500)
          .type('<br/>')
          .pause(1000)
          .type('okay <br/>')
          .pause(500)
          .type('<br/>')
          .pause(250)
          .type('wow really...?<br/>')
          
        return instance;
      };
  
      console.log(answerstring)
      console.log(artists)
    return (
        <div className="Main">
            <header className="Main-header">
                <h1>Hello, Im Tommy. </h1>
                <div className='typing'>
                    {!token ? 
                    <div className="div-1" id='div-1-1'>
                        <TypeIt
                            className = 'type-1'
                            options={{
                            speed: 25,
                            waitUntilVisible: true,
                            lifeLike: true,
                            cursor: false,
                            }}
                            getBeforeInit={typeit}
                        />
                    </div> :
                    <div className="div-1" id='div-1-2'>

                        {/* <TypeIt
                            className = 'type-1'
                            options={{
                            speed: 25,
                            waitUntilVisible: true,
                            lifeLike: true,
                            cursor: false,
                            }}
                            getBeforeInit={converse1}
                        /> */}
                    </div>
                    }
                </div>

                <div className='button-1' id='b1-1' style={{display:'none'}}>
                    <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&response_type=${RESPONSE_TYPE}`}>Login to Spotify</a>
                </div>
                <div className='button-1' id='b1-2' style={{display:'none'}} onClick={changeButton}>
                    <span className='pseudo-button'>  Wait what do you mean you need my spotify </span>
                </div>


                <div className='typing' id='c1' style={{display:'none'}}>
                {!token ? 
                    <div className="div-1" id='div-1-1'>
                        <TypeIt
                            className = 'type-1'
                            options={{
                            speed: 25,
                            waitUntilVisible: true,
                            lifeLike: true,
                            cursor: false,
                            }}
                            getBeforeInit={converse1}
                        />
                    </div> :
                    <div className="div-1" id='div-1-2'>
                    </div>
                    }
                </div>

                <div className='button-1' id='b1-3' style={{display:'none'}}>
                    <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&response_type=${RESPONSE_TYPE}`}>Login to Spotify</a>
                </div>

                {/* main converse after data is ported */}

                {!token ?<div></div> 
                    : 
                    <div className='wrapper' onLoad={getData}>
                        <div className="div-1" id='div-1-1'>
                            <TypeIt
                                className = 'type-1'
                                options={{
                                speed: 25,
                                waitUntilVisible: true,
                                lifeLike: true,
                                cursor: false,
                                }}
                                getBeforeInit={converse2}
                            />
                        </div>
                        
                        <div className='button-1' id='b1-4' style={{display:'none'}} onClick={changeButtonOne}>
                            <span className='pseudo-button'>  Yes </span>
                        </div>
                        <div className='button-1' id='b1-5' style={{display:'none'}} onClick={changeButtonTwo}>
                            <span className='pseudo-button'> No </span>
                        </div>
                        {/* <div className='typing'> */}
                        <div className="div-1" id='c3' style={{display:'none'}}>
                            <TypeIt
                                className = 'type-1'
                                options={{
                                speed: 25,
                                waitUntilVisible: true,
                                lifeLike: true,
                                cursor: false,
                                }}
                                getBeforeInit={converse3}
                            />
                            <h3> {username}</h3>
                        {/* </div> */}
                        </div>

                    </div>
                }

                <button onClick={logout}>Logout</button>

               
            </header>
            
        
        </div>



    );
}

export default Main;