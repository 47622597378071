import './Landing.css';
import { useNavigate } from "react-router-dom"



function Landing() {

    const navigate = useNavigate();

    const navigateToMain = () => {
        // 👇️ navigate to /contacts
        navigate('/main/');
      };

    return (
        
        <div className="Landing">
            <div className='logo-div'> hello</div>
        <header className="Landing-header">
            <div className='headers'>
            <h1>Can you be trusted on Aux at USC?</h1>
            <h2>blah blah blah blah blah</h2>
            </div>
            <div className='button-div'>
                <button className='l-button' onClick={navigateToMain} >Analyze your Spotify</button>
            </div>
            
        
        </header>
        <div className='footer-div'>
        <text> this is a joke</text>
        <text> blah blah</text>
        </div>

        </div>

  );
}

export default Landing;
